<template>
    <v-card flat style="height: 1000px;">
        <v-card-text>
          <v-row class="mb-3">
            <v-col cols="12">
              <h3>Update Session</h3>
            </v-col>

            <v-col cols="12" md="10">
              <v-autocomplete
                  :error="$v.program.session_id.$error"
                  dense
                  outlined
                  v-model="program.session_id"
                  :items="program_sessions"
                  item-text="title"
                  item-value="id"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  label="Academic sessions"
              >
                <template v-slot:label>
                  Academic sessions
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.selectLocation.$error">This Field is Required</span>

            </v-col>


            <v-col cols="12" md="2">
              <v-btn
                  @click="createOrUpdateProgram"
                  class="btn btn-primary w-25 text-white text-right"
              >Update
              </v-btn>

            </v-col>
          </v-row>
            <v-row class="mb-3">
                <v-col cols="12">
                   <h3> Branch location</h3>
                </v-col>

                <v-col cols="12" md="12">
                    <v-autocomplete
                            :loading="isLoading"
                            :items="programSessions"
                            @change="getProgramLocations"
                            dense
                            item-text="title"
                            item-value="id"
                            :error="$v.sessionId.$error"
                            label="Select a session"
                            outlined
                            clearable
                            v-model="sessionId"
                    >
                    </v-autocomplete>
                  <span class="text-danger" v-if="$v.sessionId.$error">This Field is Required</span>

                </v-col>

                <v-col cols="12" md="4" v-if="sessionId">
                    <v-autocomplete
                            :loading="isLoading"
                            :items="institutionLocations"
                            @change="changeLocation()"
                            dense
                            item-text="address_line_1"
                            item-value="id" :error="$v.selectLocation.$error"
                            label="Select a Location "
                            outlined
                            prepend-inner-icon="fas fa-globe-americas"
                            return-object
                            clearable
                            v-model="selectLocation"
                    >
                    </v-autocomplete>
                  <span class="text-danger" v-if="$v.selectLocation.$error">This Field is Required</span>

                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                      @click="createOrUpdateLocation"
                      class="btn btn-primary w-25 text-white text-right"
                  >Add/Update
                  </v-btn>

                </v-col>
            </v-row>

            <v-row v-if="locations.length > 0">
              <v-col cols="12" md="6" v-for="(location,index) in locations" :key="index" >
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                  <v-card-title>
                    {{location.country}}, {{location.state}}, {{location.city}}
                    <v-spacer></v-spacer>

                    <div>
                      <v-btn
                          class="ml-2"
                          :color="location.is_active ? 'green' : 'red'"
                          icon
                          x-small
                          @click="updateStatus(location) "
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on"> fas fa-circle-dot</v-icon>
                          </template>
                          {{ location.is_active ? 'Active' : 'Inactive' }}
                        </v-tooltip>

                      </v-btn>

                    </div>
                  </v-card-title>

                  <v-card-subtitle>
                    <v-icon>
                      fas fa-map-marker-alt
                    </v-icon>
                    {{location.address_line_1}}, {{location.address_line_2}}
                  </v-card-subtitle>
                  <v-card-text>
                    <div class="mb-2" v-if="location.university_email">
                      <v-icon>
                        fas fa-envelope
                      </v-icon>
                      {{location.university_email}}
                    </div>
                    <div v-if="location.phone_no">
                      <v-icon>
                        fas fa-phone
                      </v-icon>
                      {{location.country_code}} {{location.phone_no}}
                    </div>
                    <div v-html="location.google_map" v-if="location.google_map">
                    </div>
                  </v-card-text>

                </v-card>
              </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
  import { required} from "vuelidate/lib/validators";
  import InstitutionService from "@/services/academic/institution/InstitutionService";
  import LocationService from "@/services/academic/institution/location/LocationService";
  import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
  import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";

  const institution = new InstitutionService();
  const locationService = new LocationService();
  const academicProgram = new AcademicProgramService();
  const programSession = new AcademicProgramSessionService();

  export default {
  name: "Program-Locations",
  props: ['institution_id', 'program'],
  data() {
    return {
      loading:false,
      sessionId: null,
      selectLocation: {},
      locations: [],
      programSessions: [],
      institutionLocations: [],
      program_sessions: [],
      isLoading: false,
    }
  },
  validations: {
    program: {
      session_id: { required },
    },
    sessionId: { required },
    selectLocation: { required },
  },
  computed: {
    program_id() {
        return this.$route.params.program_id;
    }
  },

  mounted() {
    this.getAcademicProgramSessions();
    this.getAvailableSessionForLocation();
  },
  methods: {
    getAcademicProgramSessions() {
      programSession.all().then(response => {
        this.program_sessions = response.data.academicProgramSessions
      }).catch(error => {
        this.program_sessions = [];
      })
    },
    getAvailableSessionForLocation() {
      programSession.getSessionByProgram(this.program_id).then(response => {
        this.programSessions = response.data.sessions
      }).catch(error => {
        this.programSessions = [];
      })
    },
    changeLocation() {
        if(this.selectLocation) {
          if(this.selectLocation.academic_program_ids == null) {
            this.selectLocation.academic_program_ids = [];
            this.selectLocation.academic_program_ids.push(this.program_id);
          }else  {
            this.selectLocation.academic_program_ids.push(this.program_id);
          }
        }
    },
    getProgramLocations() {
      locationService
            .getProgramAddress(this.program_id, this.sessionId)
            .then((response) => {
                this.locations = response.data.data;
                this.getInstitutionsLocations();
            })
            .catch(() => {})
    },
    getInstitutionsLocations() {
      locationService
            .getInstitutionsLocations(this.institution_id, this.program_id, this.sessionId)
            .then((response) => {
                this.institutionLocations = response.data.data;
            })
            .catch(() => {})
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.program) {
        if (key === "file" && this.program[key] != null && this.program[key] != undefined) {
          formData.append('file', this.program[key]);
        } else if (key === "is_featured") {
          let is_featured = this.program[key] ? 1 : 0;
          formData.append('is_featured', is_featured);
        } else {
          if (this.program[key]) {
            formData.append(key, this.program[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdateProgram() {
      this.$v.$touch()
      if (this.$v.program.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        this.updateProgram();
      }
    },
    updateProgram: function () {
      let form = this.convertToFormData();
      academicProgram
          .update(this.program.id, form)
          .then(() => {
            this.loading = false;
            this.$snotify.success("Updated successfully");
            this.selectLocation = {};
            this.$v.$reset();
            this.getAcademicProgramSessions();
            this.getAvailableSessionForLocation();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    createOrUpdateLocation() {
         this.$v.$touch()
         if (this.$v.selectLocation.$error) {
             setTimeout(() => {
                 this.$v.$reset()
             }, 3000);
         } else {
           this.loading = true;
            this.update();
         }
    },
    update: function () {
      let data = {
        location_id: this.selectLocation.id,
        academic_program_id: this.program_id,
        session_id: this.sessionId,
      };
        locationService
            .updateForProgramAndSession(this.selectLocation.id, data)
            .then(response => {
                this.loading = false;
                this.$snotify.success("Locations Updated successfully");
                this.selectLocation = {};
                this.$v.$reset();
                this.getProgramLocations();
            })
            .catch((err) => {
                this.loading = false;
                this.$snotify.error("Oops something went wrong");
                this.errors = err.errors;
            });
    },
    updateStatus(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            let data = {
              is_active: !item.is_active
            };
            locationService
                .updateProgramLocation(item.id, data)
                .then((response) => {
                  if(response.data.status === "OK") {
                    this.$snotify.success("Location update successfully !!");
                    this.getProgramLocations();
                  }
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
     __delete(item) {
          this.$confirm({
              message: `Are you sure? `,
              button: {
                  no: "No",
                  yes: "Yes",
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: (confirm) => {
                  if (confirm) {
                      locationService
                          .deleteProgramLocation(item.location_id, this.program_id)
                          .then((response) => {
                              this.getProgramLocations();
                              this.$snotify.success("Locations Successfully Deleted");
                          })
                          .catch((err) => {
                              this.isLoading = false;
                              this.$snotify.error("Oops something went wrong");
                          });
                  }
              },
          });
      }
  }
}

</script>